import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Buffer } from 'buffer';
import { Fragment, useEffect, useState } from "react";
import { getCookie, getProjects } from "../utils";

function Projects({selectedProject, handleSetSelectedProject}) {
    const navigate = useNavigate()
    const [projects, setProjects] = useState([])

    const getMyProjects = async () => {
        await getProjects().then((data) => {
            setProjects(data)
        })
    }
    useEffect(() => {
        const foundProject = projects.find((item) => {
            return item.id == selectedProject
        })
        if (foundProject != undefined) {
            handleSetSelectedProject(foundProject.name)
        }

    }, [projects, selectedProject])
    useEffect(() => {
        if (getCookie('JiraToken') != undefined) {
            getMyProjects()
        } else {
            navigate('/authorize')
        }
        
    }, [false])
    return  <ul className="projects-list">
                {projects.length > 0 && projects.map((item, key) => {
                    return <li className={`projects-item ${selectedProject == item.id ? 'active' : ''}`}><Link to={`/project/${item.id}`}>{item.name}</Link></li>
                })}
            </ul>
    
};

export default Projects;