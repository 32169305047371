import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { setCookie } from "../utils";
import { Buffer } from 'buffer';

function Authorize() {
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    
    const submitData = ({ email, token, ClockToken }) => {
        setCookie('ClockToken', ClockToken, {expires: new Date(new Date().setMonth(new Date().getMonth() + 2))})
        setCookie('JiraToken', Buffer.from(`${email}:${token}`).toString('base64'), {expires: new Date(new Date().setMonth(new Date().getMonth() + 2))})
        navigate('/project');
    };

    return <div className="authorize__container">
                <Container>
                    <Row>
                        <Col className="authorize__col" xs={12}>
                            <Form onSubmit={handleSubmit(submitData)}>
                                <Form.Group className="authorize__input mb-3">
                                    <Form.Control {...register('email')} required type='email' name='email' placeholder="Enter your email address" />
                                </Form.Group>
                                <Form.Group className="authorize__input mb-3">
                                    <Form.Control {...register('token')} minLength="24" required type='text' name='token' placeholder="Enter your Jira token" />
                                </Form.Group>
                                <Form.Group className="authorize__input mb-3">
                                    <Form.Control {...register('ClockToken')} required type='text' name='ClockToken' placeholder="Enter your token for clockwork" />
                                </Form.Group>
                                <div className="authorize__botton">
                                    <Button className="authorize__submit" type='submit'>Submit</Button>
                                </div>
                                
                            </Form>
                        </Col>
                    </Row>
            </Container>
        </div>
        
    
};

export default Authorize;