import {combineReducers} from 'redux';
import issueReducer from './issue/reducer';
import timerReducer from './timer/reducer';
import userReducer from './user/reducer';

const allReducers = combineReducers({
    user: userReducer,
    timer: timerReducer,
    issue: issueReducer,
});

export default allReducers;
