import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { Outlet, redirect, useNavigate, useParams } from "react-router-dom";
import { deleteCookie, setCookie } from "../utils";
import { Buffer } from 'buffer';
import { Fragment, useEffect, useState } from "react";
import Projects from "./Projects";
import Issues from "./Issues";
import TimerInfo from "./TimerInfo";
import { useDispatch } from "react-redux";
import { user_remove } from "../store/user/action";

function Main() {
    const { projectId, issueId } = useParams()

    const [projectName, setProjectName] = useState("")
    const [issueTitle, setIssueTitle] = useState("")

    const handleSetSelectedProject = (projectName) => {
        console.log(projectName)
        setProjectName(projectName)
    }
    const handleSetSelectedIssue = (issueTitle) => {
        setIssueTitle(issueTitle)
    }
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const logOut = () => {
        dispatch(user_remove())
        deleteCookie("ClockToken")
        deleteCookie("JiraToken")
        console.log("logged out")
        return navigate("/authorize")
    }
    const refresh = () => {
        // window.location.href = window.location.href
        // history.go(0)
        window.location.reload()
    }

    useEffect(() => {
        console.log(projectId, issueId)
    }, [projectId, issueId])
    return <Fragment>
            <h1 className="main__logo">VepBit<span>Tracker</span></h1>
            <div className="main__btn-box">
                <Button onClick={logOut}>Log out</Button>
                <Button onClick={refresh}>Refresh</Button>
            </div>
        <Container fluid="lg">
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col sm={4} lg={3}>
                            <TimerInfo projectName={projectName} issueTitle={issueTitle} issueId={issueId} />
                            <Projects handleSetSelectedProject={handleSetSelectedProject} selectedProject={projectId} />
                        </Col>
                        <Col sm={8} lg={9}>
                            {projectId != undefined && <Issues handleSetSelectedIssue={handleSetSelectedIssue} selectedProject={projectId} selectedIssue={issueId} />}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </Fragment>

};

export default Main;