import {TIMER_REMOVE, TIMER_SET} from './action';

const timer = {};

const timerReducer = ( state = timer, action ) =>{
    switch(action.type){
        case TIMER_SET: 
            state = action.payload
            return state
        case TIMER_REMOVE: 
            state = {}
            return state
        default:
            return state
    }
}

export default timerReducer;