export const ISSUE_SET = 'ISSUE_SET';
export const ISSUE_REMOVE = 'ISSUE_REMOVE';

export const issue_set = (data) => {
    return{
        type: ISSUE_SET,
        payload: data
    }
}

export const issue_remove = () => {
    return{
        type: ISSUE_REMOVE
    }
}

