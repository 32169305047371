import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getMe, getWorklogs, msToTime, setCookie } from "../utils";
import { Buffer } from 'buffer';
import { Fragment, useEffect, useState } from "react";
import Projects from "./Projects";
import Issues from "./Issues";

function TimeSpent({account_id, starting_at, callBack}) {
    const [timeTracked, setTimeTracked] = useState(0)
    const getTimeSpent = async () => {
        let worklogs = await getWorklogs({account_id, starting_at})
        let tmp = 0
        for (const worklog of worklogs) {
            tmp += worklog.timeSpentSeconds
        }
        callBack(tmp * 1000)
        setTimeTracked(tmp * 1000)
    }
    useEffect(() => {
        getTimeSpent()
    }, [account_id])
    
    // const {projectId, issueId} = useParams()
    return  <div className="timer">
                {(() => {
                    let timeLeft = msToTime(timeTracked)
                    return <Fragment>{timeLeft.h + ':' + timeLeft.m + ':' + timeLeft.s}</Fragment>
                })()}
        </div>  
    
};

export default TimeSpent;