export const USER_SET = 'USER_SET';
export const USER_REMOVE = 'USER_REMOVE';

export const user_set = (data) => {
    return{
        type: USER_SET,
        payload: data
    }
}

export const user_remove = () => {
    return{
        type: USER_REMOVE
    }
}

