const API_URL = 'https://tracking.node.vepbit.com'

export const msToTime = (ms) => {
    let s = Math.floor((ms / 1000) % 60),
        m = Math.floor((ms / (1000 * 60)) % 60),
        h = Math.floor((ms / (1000 * 60 * 60)) % 24);

    h = (h < 10) ? "0" + h : h;
    m = (m < 10) ? "0" + m : m;
    s = (s < 10) ? "0" + s : s;
    return {
        h,
        m,
        s
    };
}

export const setCookie = (name, value, options = {}) => {
    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };
    
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
    
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    
    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    
    document.cookie = updatedCookie;
}
export const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
export const deleteCookie = (name) => {
    setCookie(name, "", {
        'max-age': -1
    })
}
export const getMe = async () => {
    try {
        const data = await fetch(`${API_URL}/myself`, {
            method: 'get',
            headers: {
                'Authorization': `Basic ${getCookie('JiraToken')}`,
            }
        }).then(res => res.json());
        return data;
    } catch (response) {
        const error = {
            response,
        }
        return error
    }
}
export const getProjects = async () => {
    try {
        const {values} = await fetch(`${API_URL}/projects-active`, {
            method: 'get',
            headers: {
                'Authorization': `Basic ${getCookie('JiraToken')}`,
            }
        }).then(res => res.json());
        return values;
    } catch (response) {
        const error = {
            response,
        }
        return error
    }
}
export const getIssuesByProject = async (id) => {
    try {
        const {issues} = await fetch(`${API_URL}/issues-assigned/${id}`, {
            method: 'get',
            headers: {
                'Authorization': `Basic ${getCookie('JiraToken')}`,
            }
        }).then(res => res.json());
        console.log(issues)
        return issues;
    } catch (response) {
        const error = {
            response,
        }
        return error
    }
}
export const enableTimer = id => {
    return fetch(`${API_URL}/timerForIssue/${id}`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('ClockToken')}`,
            //'Accept': '*/*',
            //'Access-Control-Allow-Origin': '*/*'
        }
    }).then(res => res.json()).then(data => {
        console.log(data)
        return data
    })
};

export const disableTimer = id => {
    return fetch(`${API_URL}/stopTimerForIssue/${id}`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('ClockToken')}`,
            //'Accept': '*/*',
            //'Access-Control-Allow-Origin': '*/*'
        }
    }).then(res => res.json()).then(data => {
        console.log(data)
        return data
    })
};

export const getWorklogs = ({account_id, starting_at}) => {
    return fetch(`${API_URL}/getWorklogs`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('ClockToken')}`,
        },
        body: JSON.stringify({account_id, starting_at})
    }).then(res => res.json()).then(data => {
        console.log(data)
        return data
    })
};