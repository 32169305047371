import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import { Buffer } from 'buffer';
import { Fragment, useEffect, useState } from "react";
import { getIssuesByProject, getWorklogs, msToTime } from "../utils";
import StartStopTimer from "./StartStopTimer";
import { useSelector } from "react-redux";

function Issues({handleSetSelectedIssue, selectedProject, selectedIssue}) {
    const [issues, setIssues] = useState([])
    const user = useSelector(state => state.user)
    const timer = useSelector(state => state.timer)
    const getMyIssues = async () => {
        await getIssuesByProject(selectedProject).then((data) => {
            getTimeSpent(data)
        })
    }
    const getTimeSpent = async (tmp) => {
        console.log(tmp.length)
        if (tmp.length > 0) {
            let worklogs = await getWorklogs({account_id: user.accountId, starting_at: new Date().toLocaleDateString('fr-CA')})
            for (let i = 0; i < worklogs.length; i++) {
                let index = tmp.findIndex((item) => {
                    return item.id == worklogs[i].issueId
                })
                if (index != -1) {
                    if (!tmp[index].fields.timespenttoday) {
                        tmp[index].fields.timespenttoday = 0
                    }
                    tmp[index].fields.timespenttoday += worklogs[i].timeSpentSeconds
                }
            }
            setIssues(tmp)
        }
    }
    useEffect(() => {
        getMyIssues()
    }, [selectedProject])

    // useEffect(() => {
    //     getTimeSpent()
    // }, [issues.length != 0])
    
    useEffect(() => {
        const foundIssue = issues.find((item) => {
            return item.id == selectedIssue
        })
        if (foundIssue != undefined) {
            handleSetSelectedIssue(foundIssue.fields.summary)
        }

    }, [issues, selectedIssue])
    return  <table className="issues-list">
                <thead>
                    <tr>
                        <th>Summary</th>
                        <th title="Time spent today">Today</th>
                        <th title="Time spent total">Total</th>
                        {/* <th>Original estimate</th> */}
                        <th>Open in Jira</th>
                    </tr>
                </thead>
                <tbody>
                    {issues.length > 0 && issues.map((item, key) => {
                        return  <tr className={`issues-item ${selectedIssue == item.id ? 'active' : ''} ${timer.issueId ? 'disabled' : ''}`}>
                                    <td className="item-summary">
                                        <StartStopTimer projectId={selectedProject} issueId={item.id} issueName={item.fields.summary}/>
                                        <Link to={`issue/${item.id}`} title={item.fields.summary}>{item.fields.summary}</Link>
                                    </td>
                                    <td className={`item-timespenttoday`}>
                                        {(() => {
                                            let timespent = msToTime(item.fields.timespenttoday ? item.fields.timespenttoday * 1000 : 0)
                                            return <Fragment>{timespent.h + ':' + timespent.m + ':' + timespent.s}</Fragment>
                                        })()}
                                    </td>
                                    <td className={`item-timespent ${item.fields.timespent < item.fields.timeoriginalestimate ? 'success' : 'danger'}`}>
                                        {(() => {
                                            let timespent = msToTime(item.fields.timespent * 1000)
                                            return <Fragment>{timespent.h + ':' + timespent.m + ':' + timespent.s}</Fragment>
                                        })()}
                                    </td>
                                    {/* <td className="item-timeoriginalestimate">
                                        {(() => {
                                            let timeoriginalestimate = msToTime(item.fields.timeoriginalestimate * 1000)
                                            return <Fragment>{timeoriginalestimate.h + ':' + timeoriginalestimate.m + ':' + timeoriginalestimate.s}</Fragment>
                                        })()}
                                    </td> */}
                                    <td><Link target="_blank" to={`${item.self.split('/rest')[0]}/browse/${item.key}`} className="item-link">{item.key}</Link></td>
                                </tr>
                    })}
                </tbody>
            </table>
    
};

export default Issues;