import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getMe, setCookie } from "../utils";
import { Buffer } from 'buffer';
import { Fragment, useEffect, useState } from "react";
import Projects from "./Projects";
import Issues from "./Issues";
import Timer from "./Timer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import StartStopTimer from "./StartStopTimer";
import TimeSpent from "./TimeSpent";
import { useDispatch, useSelector } from "react-redux";
import { timer_set } from "../store/timer/action";
import { user_set } from "../store/user/action";

function TimerInfo({projectName, issueTitle, issueId}) {
    const user = useSelector(state => state.user)
    const [selectedProject, setSelectedProject] = useState(projectName)
    const [selectedIssue, setSelectedIssue] = useState(issueTitle)
    const [selectedIssueId, setSelectedIssueId] = useState(issueId)
    //const [timerStarted, setTimerStarted] = useState(!!localStorage.getItem('time'))
    const timer = useSelector(state => state.timer)
    const issue = useSelector(state => state.issue)
    const dispatch = useDispatch()
    const [workedToday, setWorkedToday] = useState(0)

    useEffect(() => {
        if (!timer.active) {
            setSelectedProject(projectName)
            setSelectedIssue(issueTitle)
            setSelectedIssueId(issueId)
        } else {
            setSelectedIssue(issue.issue)
            setSelectedIssueId(issue.id)
        }
    }, [projectName, issueTitle, timer])
    const getUserInfo = async () => {
        dispatch(user_set(await getMe()))
    }
    useEffect(() => {
        getUserInfo()
    }, [false])
    // const {projectId, issueId} = useParams()
    return  <div className="timer-info">
                <div className="user">{user.displayName}</div>
                <div className="current-time">
                    <StartStopTimer issueId={selectedIssueId} issueName={selectedIssue}/>
                    {timer.active && <Timer time={workedToday} isGlobal={true} />}
                    {!timer.active && <TimeSpent account_id={user.accountId} starting_at={new Date().toLocaleDateString('fr-CA')} callBack={(time) => setWorkedToday(time)}/>}
                </div>
                <div className="project-name">{selectedProject}</div>
                <div className="issue-title">{selectedIssue}</div>
            </div>
    
};

export default TimerInfo;