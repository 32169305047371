import {ISSUE_REMOVE, ISSUE_SET} from './action';

const issue = {};

const issueReducer = ( state = issue, action ) =>{
    switch(action.type){
        case ISSUE_SET: 
            state = action.payload
            return state
        case ISSUE_REMOVE: 
            state = {}
            return state
        default:
            return state
    }
}

export default issueReducer;