import logo from './logo.svg';
import './App.css';
import './style/index.sass';
import { Route, BrowserRouter as Router, Navigate, useLocation, Routes } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import { getCookie } from './utils';
import Authorize from './components/Authorize';
import Main from './components/Main';
import Issues from './components/Issues';

function App() {
    const tokensExist = getCookie('ClockToken') && getCookie('JiraToken')
    const confirmExit = () => {
        return "You have attempted to leave this page. Are you sure?";
    }
    window.onbeforeunload = confirmExit;
    return  <Routes>
                <Route exact path='/' element={
                    <Fragment>
                        {!tokensExist && <Navigate to={"authorize"}/>}
                        {tokensExist && <Navigate to={"project"}/>}
                    </Fragment>
                }/>
                <Route exact path='project' element={<Main />} />
                <Route path='project/:projectId' element={<Main />} >
                    <Route path='issue/:issueId' element={<Main />} />
                </Route>
                <Route path='/authorize' element={<Authorize />} />
            </Routes>
}
export default App;
