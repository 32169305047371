import {USER_REMOVE, USER_SET} from './action';

const user = {};

const userReducer = ( state = user, action ) =>{
    switch(action.type){
        case USER_SET: 
            state = action.payload
            return state
        case USER_REMOVE: 
            state = {}
            return state
        default:
            return state
    }
}

export default userReducer;