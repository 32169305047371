import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { disableTimer, enableTimer, getMe, setCookie } from "../utils";
import { Buffer } from 'buffer';
import { Fragment, useEffect, useState } from "react";
import Projects from "./Projects";
import Issues from "./Issues";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Notify } from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import { timer_set } from "../store/timer/action";
import { issue_set } from "../store/issue/action";

function StartStopTimer({issueId, projectId, issueName}) {
    const timer = useSelector(state => state.timer)
    const dispatch = useDispatch()
    const startStopTimer = async () => {
        let data = {}
        if (!timer.active) {
            data = await enableTimer(issueId)
            if (data.messages) {
                dispatch(timer_set({active: true, issueId, projectId}))
                dispatch(issue_set({id: issueId, issue: issueName}))
            }
            if (!!window) {
                document.querySelector("link[rel*='icon'][sizes='32x32']").href = "/new-favicon.png";
                document.querySelector("link[rel*='icon'][sizes='16x16']").href = "/new-favicon.png";
            }
        } else {
            if (!!window) {
                document.querySelector("link[rel*='icon'][sizes='32x32']").href = "/android-chrome-512x512.png";
                document.querySelector("link[rel*='icon'][sizes='16x16']").href = "/android-chrome-192x192.png";
            }
            data = await disableTimer(issueId)
            if (data.messages) {
                dispatch(timer_set({active: false}))
                dispatch(issue_set({}))
            }
        }
        if (data.messages) {
            Notify.success(`${data.messages[0].title}\n${data.messages[0].body}`)
        } else { 
            Notify.failure(`Something went wrong. Try again`)
        }
    }
    return  <Button disabled={issueId == undefined && !timer.active} className={timer.active && timer.issueId == issueId ? `active` : ''} onClick={startStopTimer}><FontAwesomeIcon icon={timer.active && timer.issueId == issueId ? faStop : faPlay}/></Button>
    
};

export default StartStopTimer;