import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getMe, msToTime, setCookie } from "../utils";
import { Buffer } from 'buffer';
import { Fragment, useEffect, useState } from "react";
import Projects from "./Projects";
import Issues from "./Issues";

function Timer({time, isGlobal}) {
    const [timeTracked, setTimeTracked] = useState(0)
    useEffect(() => {
        if (isGlobal) {
            setTimeTracked(time)
        } else {
            setTimeTracked(!localStorage.getItem('time') ? 0 : new Date() - new Date(JSON.parse(localStorage.getItem('time')).startedAt))
        }
        
        const timeInterval = setInterval(() => {
            setTimeTracked((prevTime) => Number(prevTime) + 1000);
        }, 1000);
        return () => clearInterval(timeInterval);
    }, [false])
    
    // const {projectId, issueId} = useParams()
    return  <div className="timer">
                {(() => {
                    let timeLeft = msToTime(timeTracked)
                    return <Fragment>{timeLeft.h + ':' + timeLeft.m + ':' + timeLeft.s}</Fragment>
                })()}
        </div>  
    
};

export default Timer;