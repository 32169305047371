export const TIMER_SET = 'TIMER_SET';
export const TIMER_REMOVE = 'TIMER_REMOVE';

export const timer_set = (data) => {
    return{
        type: TIMER_SET,
        payload: data
    }
}

export const timer_remove = () => {
    return{
        type: TIMER_REMOVE
    }
}

